import React from "react"
import styles from "./about-page.module.scss"
import Iframe from "./iframe.js"
import Breadcrumb from '../../../components/controls/breadcrumbs';

const iframeSourceSamara = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3638.8306448302446!2d50.193992611008625!3d53.225180409363766!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3fde2e1531e41168!2sHaulmont!5e0!3m2!1sru!2sru!4v1570029454051!5m2!1sru!2sru'
const iframeSourceLondon = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.337924860308!2d-0.1414522840744686!3d51.5253615172105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b287448dfcd%3A0x8dc72e1a5ea6bae6!2z0K7RgdGC0LXQvSDQotCw0YPRjdGALCAyODYgRXVzdG9uIFJkLCBMb25kb24gTlcxIDNEUCwg0JLQtdC70LjQutC-0LHRgNC40YLQsNC90LjRjw!5e0!3m2!1sru!2sru!4v1569944702271!5m2!1sru!2sru'
const AboutPage = () => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'О нас', url: ''}
  ];
  return (
  <div className={styles.container}>
    <div className={styles.sectionHero}>
      <div className={styles.heroTitle}>Параплан - продукт российской<br/> ИТ-компании Хоулмонт</div>
      <div className={styles.subtitle}>«Хоулмонт» более 15 лет занимается автоматизацией бизнеса, создавая уникальные решения для компаний, которые используют технологии для своего роста и эффективного развития.
        <div className={styles.logo}><img src={require("../../../images/about/haulmont.svg")} alt={"Холмонт логотип"}/></div>
    </div>
    </div>

    <div className={styles.sectionSummary}>
      <div className={styles.sectionSummaryContainer}>
        <div className={styles.sectionSummaryItem}>
          <div className={styles.itemBold}>15 лет</div>
          <div className={styles.itemText}>разностороннего<br/> опыта</div>
        </div>
        <div className={styles.sectionSummaryItem}>
          <div className={styles.itemBold}>700+</div>
          <div className={styles.itemText}>реализованных<br/>проектов</div>
        </div>
        <div className={styles.sectionSummaryItem}>
          <div className={styles.itemBold}>800+</div>
          <div className={styles.itemText}>продуктовых<br/>внедрений </div>
        </div>
        <div className={styles.sectionSummaryItem}>
          <div className={styles.itemBold}>170+ стран</div>
          <div className={styles.itemText}>в которых используются<br/> продукты компании</div>
        </div>
      </div>
    </div>
    <Breadcrumb crumbs={ crumbs } arrowcolor={"blue"}/>
    <div className={styles.sectionProducts}>
      <div className={styles.title}>Продукты «Хоулмонт»</div>
      <div className={styles.sectionProductsList}>
        <a href="https://www.tezis-doc.ru/" rel="nofollow" className={styles.sectionProductsItem}>
          <div className={styles.itemImage}><img src={require("../../../images/about/tezis.png")} alt={"Тезис"}/></div>
          <div className={styles.itemText}>Система электронного документооборота</div>
        </a>
        <a href="https://www.jmix.ru/" rel="nofollow" className={styles.sectionProductsItem}>
          <div className={styles.itemImage}><img src={require("../../../images/about/jmix.jpg")} alt={"Jmix"}/></div>
          <div className={styles.itemText}>Платформа для разработки корпоративных приложений</div>
        </a>
        <a href="https://paraplancrm.ru/" className={styles.sectionProductsItem}>
          <div className={styles.itemImage}><img src={require("../../../images/about/paraplan.png")} alt={"Параплан"}/></div>
          <div className={styles.itemText}>CRM-система учета для бизнеса</div>
        </a>
        <a href="https://www.haulmont.ru/products/printera/" rel="nofollow" className={styles.sectionProductsItem}>
          <div className={styles.itemImage}><img src={require("../../../images/about/printera.png")} alt={"Printera"}/></div>
          <div className={styles.itemText}>Решение по управлению печатной инфраструктурой</div>
        </a>
      </div>
      <div className={styles.title}>Компании сотрудничают с «Хоулмонт» для создания нового подхода к построению бизнеса</div>
      <div className={styles.subtitle}>Клиенты рассматривают «Хоулмонт»  как технологического партнера, а не как поставщика решения. Это говорит о постоянной совместной работе в достижении общих целей. </div>
      <div className={styles.logoList}>
        <img src={require("../../../images/about/logoPsb.png")} alt={"Логотип ПСБ"}/>
        <img src={require("../../../images/about/logoIngosstrah.png")} alt={"Логотип Ингосстрах"}/>
        <img src={require("../../../images/about/logoGazprom.png")} alt={"Логотип Газпром"}/>
        <img src={require("../../../images/about/logoRosatom.png")} alt={"Логотип РосАтом"}/>
        <img src={require("../../../images/about/logoRoscosmos.png")} alt={"Логотип Роскосмос"}/>
      </div>
    </div>

    <div className={styles.sectionProjects}>
      <div className={styles.sectionProjectsContainerMain}>
      <div className={styles.title}>С <span className={styles.blue}>2008 года</span> нам удалось</div>
      <div className={styles.subtitle}>поработать с выдающимися людьми и создать классные решения для крупного бизнеса</div>
      <div className={styles.sectionProjectsContainer}>
      <div className={styles.sectionProjectsColumn}>
        <div className={styles.projectItem}>
          <div className={styles.imageWrapper}>
            <img className={styles.preview} src={require("../../../images/about/caepco.png")} alt={"Caepco"}/>
          </div>
          <div className={styles.decrsWrapper}>
            <img className={styles.logoCaepco} src={require("../../../images/about/logoCaepco.png")} alt={"Логотип Caepco"}/>
            <div className={styles.itemText}>Оптимизация бизнес-процессов и переход на электронный документооборот</div>
          </div>
        </div>
        <div className={styles.projectItem}>
          <div className={styles.imageWrapper}>
            <img className={styles.preview} src={require("../../../images/about/inline.png")} alt={"Inline"}/>
          </div>
          <div className={styles.decrsWrapper}>
            <img className={styles.logoInline} src={require("../../../images/about/logoInline.png")} alt={"Логотип Inline"}/>
            <div className={styles.descrText}>Оптимизация затрат, консолидация закупок и управление жизненным циклом оборудования и ПО</div>
          </div>
        </div>
        <div className={styles.projectItem}>
            <div className={styles.imageWrapper}>
              <img className={styles.preview} src={require("../../../images/about/smarts.png")} alt={"СМАРТС"}/>
            </div>
            <div className={styles.decrsWrapper}>
              <img className={styles.logoSmarts} src={require("../../../images/about/logoSmarts.png")} alt={"Логотип СМАРТС"}/>
              <div className={styles.descrText}>Интеллектуальный транспортный мониторинг на базе нейросетей</div>
            </div>
          </div>
      </div>
      <div className={styles.sectionProjectsColumn}>
          <div className={styles.projectItem}>
            <div className={styles.imageWrapper}>
              <img className={styles.preview} src={require("../../../images/about/vshe.png")} alt={"ВШЭ"}/>
            </div>
            <div className={styles.decrsWrapper}>
              <img className={styles.logoVshe} src={require("../../../images/about/logoVshe.png")} alt={"Логотип ВШЭ"}/>
              <div className={styles.descrText}>Образовательный портал для <span style={{whiteSpace: 'nowrap'}}>87 тысяч</span> человек</div>
            </div>
          </div>
          <div className={styles.projectItem}>
            <div className={styles.imageWrapper}>
              <img className={styles.preview} src={require("../../../images/about/registr.png")} alt={"Российский морской регистр судоходства"}/>
            </div>
            <div className={styles.decrsWrapper}>
              <img className={styles.logoRegistr} src={require("../../../images/about/logoRegistr.png")} alt={"Логотип Российского морского регистра судоходства"}/>
              <div className={styles.descrText}>Система автоматизации Российского морского регистра судоходства</div>
            </div>
          </div>
          <div className={styles.projectItem}>
            <div className={styles.imageWrapper}>
              <img className={styles.preview} src={require("../../../images/about/rnimu.png")} alt={"РНИМУ"}/>
              <img className={styles.country} src={require("../../../images/about/russia.png")} alt={"Россия"}/>
            </div>
            <div className={styles.decrsWrapper}>
              <img  className={styles.logoRnimu} src={require("../../../images/about/logoRnimu.png")} alt={"Логотип РНИМУ"}/>
              <div className={styles.descrText}>Федеральный портал обучения и переквалификации докторов для Н.И. Пирогова</div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>

    <div className={styles.sectionAwards}>
      <div className={styles.title}>Награды</div>
      <div className={styles.logoList}>
        <img src={require("../../../images/about/awcomp.svg")} alt={"Product IT Industry"}/>
        <img src={require("../../../images/about/iteur.svg")} alt={"IT Europa"}/>
        <img src={require("../../../images/about/softool.svg")} alt={"Softool"}/>
        <img src={require("../../../images/about/nba.svg")} alt={"NBA UK"}/>
        <img src={require("../../../images/about/computing2020.svg")} alt={"Computing 2020"}/>
      </div>
    </div>

    <div className={styles.sectionContacts}>
      <div className={styles.title}>Офис «Хоулмонт»</div>
      <div className={styles.officeContainer}>
        <div className={styles.officeItem}>
          <div className={styles.officeCity}>Самара</div>
          <div className={styles.officeCityBorder}></div>
          <div className={styles.officeAddress}>
            <img src={require("../../../images/about/icone-addr.svg")} className={styles.officesIcon} alt={"Адрес"}/>
            443090, Россия, г. Самара, ул. Гастелло, д. 43a
          </div>
          <div className={styles.officePhone}>
            <img src={require("../../../images/about/icone-phone.svg")} className={styles.officesIcon} alt={"Телефон"}/>
            +7 (846) 273 94 87</div>
          <div className={styles.officeEmail}>
            <img src={require("../../../images/about/icone-mail.svg")} className={styles.officesIcon} alt={"Email"}/>
            info@haulmont.ru</div>
        </div>
       <div className={styles.officeItem}>
         <div className={styles.officeMap}>
           <Iframe source={iframeSourceSamara} />
         </div>
        </div>
      </div>
    </div>

    <div className={styles.sectionMore}>
      <div className={styles.moreTitle}>Узнайте больше о «Хоулмонт»</div>
      <div className={styles.moreButtonWrapper}>
        <a href="https://www.haulmont.ru/?erid=2VtzquzkyY7" className={styles.moreButton} rel="nofollow">Узнать больше</a>
        <img src={require("../../../images/about/arrow.svg")} className={styles.moreArrow}/>
      </div>
    </div>

  </div>
)
}

export default AboutPage