import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactFormComponent from "../components/pages/shared/contact-form"
import AboutPageComponent from "../components/pages/about-page"

const AboutPage = (props) => (
  <Layout forceFixedHeader={true} pageClassName={"about"} headerColor={"#fff"} page={"about"}>
    <SEO 
      title="CRM-система Параплан - программа учета клиентов детских и учебных центров: абонементы, расписание, продажи, отчеты." 
      description="Параплан CRM - облачная CRM система от международной IT-компании Haulmont. CRM для учета клиентов, посещений, абонементов детских центров, сетей и франшиз, танцевальных студий, языковых школ, учебных заведений и фитнес-центров."
      url={"https://paraplancrm.ru" + props.location.pathname}
    />
    <AboutPageComponent />
    <ContactFormComponent/>
  </Layout>
)

export default AboutPage
